import { FormOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Divider, Form, Row, Typography } from 'antd';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import styled from 'styled-components';
import { DatePickerTKG, InputNumberTKG, InputTKG, ModalFormTKG, PopupStatusTKG, RangePickerTKG, SelectTKG, TextEditorTKG } from 'tkg-composite-ui';
import { listAll as ListClaimAll } from '../../api/service/claimService';
import { listAll } from '../../api/service/fineService';
import { update } from '../../api/service/paymentsService';
import { listUser } from '../../api/service/userService';
import { format_money, typeOverTime } from '../../assets/constants';
import fetchError from '../../components/functions/fetchError';

const { Title } = Typography;

const CardContent = styled.div`
    background: #fff;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 3%), 0 1px 6px -1px rgb(0 0 0 / 2%), 0 2px 4px 0 rgb(0 0 0 / 2%);
    border-radius: 10px;
    margin-top: 0.2em;
    padding: 1em;
`;

const CardFields = styled.div`
    background: rgb(240 240 240 / 49%);
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 3%), 0 1px 6px -1px rgb(0 0 0 / 2%), 0 2px 4px 0 rgb(0 0 0 / 2%);
    border-radius: 10px;
    margin-top: 0.2em;
    padding: 1em;
`;

const Update = ({ handleCallBack, dataPayment }) => {
    const [listUserId, setListUserId] = useState([]);
    const [finesAll, setFinesAll] = useState([]);
    const [claimAll, setClaimAll] = useState([]);
    const [userSelect, setUserSelect] = useState({});
    const [formUpdate] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [dataOverTime, setDataOverTime] = useState([]);
    const [validateStatus, setValidateStatus] = useState('');
    const [help, setHelp] = useState('');
    const [checkBox, setCheckBox] = useState(dataPayment?.month ? true : false);
    const [openDrawer, setOpenDrawer] = useState(false);


    const showCode = () => {
        getListUser();
        const dataField = {
            ...dataPayment,
            payment_date: dataPayment?.payment_date ? dayjs(dataPayment?.payment_date) : null,
            kpi_bonus: dataPayment?.kpi_bonus ? JSON.parse(dataPayment?.kpi_bonus) : null,
            less: dataPayment?.less ? JSON.parse(dataPayment?.less) : null,
            other: dataPayment?.other ? JSON.parse(dataPayment?.other).map((e) => ({ ...e, date: e?.date ? dayjs(e?.date) : '' })) : null,
            other_less: dataPayment?.other_less ? JSON.parse(dataPayment?.other_less).map((e) => ({ ...e, date: e?.date ? dayjs(e?.date) : '' })) : null,
            fine: dataPayment?.fine ? JSON.parse(dataPayment?.fine) : null,
            claim: dataPayment?.claim ? JSON.parse(dataPayment?.claim) : null,
            overtime: dataPayment?.overtime ? JSON.parse(dataPayment?.overtime).map((e) => ({ ...e, date: e?.date ? dayjs(e?.date) : '' })) : null,
            work_period: dataPayment?.work_period ? JSON.parse(dataPayment?.work_period).map((e) => (dayjs(e))) : null,
            month: dataPayment?.month ? dayjs(dataPayment?.month) : null,
        };
        formUpdate.setFieldsValue(dataField);
        setOpenDrawer(true);
    };
    const onClose = () => {
        formUpdate.resetFields();
        setOpenDrawer(false);
    };

    const getListUser = async () => {
        return await listUser().then((response) => {
            if (response?.data?.meta?.status === 200) {
                const dataUser = response?.data?.response.map((e) => {
                    return {
                        value: e?.id,
                        label: e?.first_name + ' ' + e?.surname,
                        ...e,
                    };
                });

                setListUserId(dataUser);
                setUserSelect(dataUser.filter((id) => id.value === dataPayment?.user_id)[0]);
            }
        });
    }

    const onFinish = async (values) => {
        const data = {
            ...values,
            type: 1,
            payment_date: values?.payment_date ? values?.payment_date.format('YYYY-MM-DD') : null,
            month: values?.month ? values?.month.format('YYYY-MM') : null,
            overtime: values.overtime ? values.overtime.map((OT) => {
                return {
                    ...OT,
                    date: OT?.date ? OT?.date?.format('YYYY-MM-DD') : '',
                };
            }) : null,
            other: values.other ? values.other.map((OTh) => {
                return {
                    ...OTh,
                    date: OTh?.date ? OTh?.date?.format('YYYY-MM-DD') : '',
                };
            }) : null,
            other_less: values.other_less ? values.other_less.map((OThL) => {
                return {
                    ...OThL,
                    date: OThL?.date ? OThL?.date?.format('YYYY-MM-DD') : '',
                };
            }) : null,
        };
        setLoading(true);
        const response = await update(dataPayment?.id, data);
        if (response?.data?.meta?.status === 200) {
            formUpdate.resetFields();
            setLoading(false);
            onClose();
            handleCallBack();
            PopupStatusTKG('success', 'Update successfully');
        } else {
            setLoading(false);
            PopupStatusTKG('error', fetchError(response));
        }
    };

    const handleChangeType = (e, key, index) => {
        const data = formUpdate.getFieldValue('overtime');
        let overtime_money = 0;
        let typeLabel = '';
        if (e === 'normal') {
            overtime_money = userSelect?.overtime_normal;
            typeLabel = 'Type Normal - ' + format_money(overtime_money) + ' - ' + userSelect.currency;
        } else if (e === 'weekend') {
            overtime_money = userSelect?.overtime_weekend;
            typeLabel = 'Type Weekend - ' + format_money(overtime_money) + ' - ' + userSelect.currency;
        } else if (e === 'holiday') {
            overtime_money = userSelect?.overtime_holiday;
            typeLabel = 'Type Holiday - ' + format_money(overtime_money) + ' - ' + userSelect.currency;
        } else {
            typeLabel = 'Type';
        }
        if (data[index]?.hours) {
            data[index] = { ...data[index], typeLabel, hours_amount: data[index]?.hours * overtime_money };
        } else {
            data[index] = { ...data[index], typeLabel, hours_amount: 0 };
        }
        setDataOverTime(data);
        formUpdate.setFieldValue('overtime', data);
    };

    const onChangeHours = (e, key, name, index) => {
        const data = formUpdate.getFieldValue('overtime');
        let overtime_money = 0;
        let typeLabel = '';
        if (data[index]?.type) {
            if (data[index].type === 'normal') {
                overtime_money = userSelect?.overtime_normal;
                typeLabel = 'Type Normal - ' + format_money(overtime_money) + ' - ' + userSelect.currency;
            } else if (data[index].type === 'weekend') {
                overtime_money = userSelect?.overtime_weekend;
                typeLabel = 'Type Weekend - ' + format_money(overtime_money) + ' - ' + userSelect.currency;
            } else if (data[index].type === 'holiday') {
                overtime_money = userSelect?.overtime_holiday;
                typeLabel = 'Type Holiday - ' + format_money(overtime_money) + ' - ' + userSelect.currency;
            } else {
                typeLabel = 'Type';
            }
            data[index] = { ...data[index], typeLabel, hours_amount: data[index]?.hours ? data[index]?.hours * overtime_money : 0 };
            setDataOverTime(data);
            formUpdate.setFieldValue('overtime', data);
        }
    };

    async function fetchAllFine(e) {
        await listAll(e).then((response) => {
            if (response?.data?.meta?.status === 200) {
                setFinesAll(
                    response?.data?.response.map((e, index) => {
                        return {
                            value: e?.id,
                            label: e?.title,
                            key: index,
                            ...e,
                            action: e,
                        };
                    }),
                );
            }
        });
    }

    async function fetchAllClaim(e) {
        await ListClaimAll(e).then((response) => {
            if (response?.data?.meta?.status === 200) {
                setClaimAll(
                    response?.data?.response.map((e, index) => {
                        return {
                            value: e?.id,
                            label: e?.title,
                            key: index,
                            ...e,
                            action: e,
                        };
                    }),
                );
            }
        });
    }

    const handleChangeClaim = (e, key, index) => {
        const data = formUpdate.getFieldValue('claim');
        const claimSelect = claimAll.find((res) => res?.id === e);
        if (claimSelect) {
            data[index] = { ...data[index], id: e, title: claimSelect?.title, amount: claimSelect?.amount };
        }
        setClaimAll(
            claimAll.map((rec, ind) => {
                return {
                    value: rec?.id,
                    label: rec?.title,
                    key: ind,
                    ...rec,
                    action: rec,
                    disabled: data.find((fil) => { return fil?.id === rec?.id })
                }
            }),
        );
        formUpdate.setFieldValue('claim', data);
    };

    const handleChangeFines = (e, key, index) => {
        const data = formUpdate.getFieldValue('fine');
        const fineSelect = finesAll.find((res) => res?.id === e);
        if (fineSelect) {
            data[index] = { ...data[index], id: e, title: fineSelect?.title, amount: fineSelect?.amount };
        }
        setFinesAll(
            finesAll.map((rec, ind) => {
                return {
                    value: rec?.id,
                    label: rec?.title,
                    key: ind,
                    ...rec,
                    action: rec,
                    disabled: data.find((fil) => { return fil?.id === rec?.id })
                }
            }),
        );
        formUpdate.setFieldValue('fine', data);
    };

    const handleGetToltalAmount = async (e) => {
        formUpdate.resetFields(['kpi_bonus', 'overtime', 'less', 'fine', 'claim']);
        setFinesAll([]);
        setClaimAll([]);
        formUpdate.setFieldsValue({ currency: listUserId.find((id) => id.value === e)?.currency, amount: listUserId.find((id) => id.value === e)?.yearly_salary });
        setValidateStatus('');
        setHelp('');
        fetchAllFine(e);
        fetchAllClaim(e);
        setUserSelect(listUserId.filter((id) => id.value === e)[0]);
    };

    const scollToUser = (icon, message) => {
        document.getElementById("refScroll").focus();
        setValidateStatus('error');
        setHelp('Please select User');
        PopupStatusTKG(icon, message);
    };

    const onChange = (e) => {
        setCheckBox(e.target.checked);
    };

    const handleChangeMonth = (e) => {
        const startOfMonth = dayjs(e.format('YYYY-MM')).startOf('month');
        const endOfMonth = dayjs(e.format('YYYY-MM')).endOf('month');
        return formUpdate.setFieldsValue({ work_period: [startOfMonth, endOfMonth] });
    }

    return (
        <>
            <Button type="primary" className="btnTKGAction" icon={<FormOutlined />} ghost onClick={showCode}>Edit</Button>
            <ModalFormTKG title="Update Payment" closable={false} onClose={onClose} open={openDrawer}>
                <CardContent>
                    <Form
                        id="basic"
                        name="basic"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        autoComplete="off"
                        layout="vertical"
                        form={formUpdate}
                        onFinish={onFinish}
                        scrollToFirstError={true}
                    >
                        <Row gutter={[16, 16]}>
                            <Col span={6}>
                                <Form.Item form={formUpdate} label="Type">
                                    <InputTKG value={'Wages'} readOnly />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    name="user_id"
                                    label="User"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select User',
                                        },
                                    ]}
                                    validateStatus={validateStatus}
                                    help={help}
                                >
                                    <SelectTKG
                                        id='refScroll'
                                        form={formUpdate}
                                        showSearch
                                        options={listUserId}
                                        onChange={handleGetToltalAmount}
                                        filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="title"
                                    label="Title"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input Title',
                                        },
                                    ]}
                                >
                                    <InputTKG form={formUpdate} placeholder="Please input Title" />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    form={formUpdate}
                                    label="Work Period"
                                    name="work_period"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select Work Period',
                                        },
                                    ]}
                                >
                                    <RangePickerTKG disabled={checkBox ? true : false} />
                                </Form.Item>
                                {checkBox ?
                                    <Form.Item
                                        form={formUpdate}
                                        label="Month"
                                        name="month"
                                    >
                                        <DatePickerTKG picker="month" format="MMM-YYYY" onChange={e => handleChangeMonth(e)} />
                                    </Form.Item>
                                    : <></>
                                }
                                <Checkbox onChange={onChange} checked={checkBox}>Monthly salary (used for Vietnam)</Checkbox>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    form={formUpdate}
                                    label="Payment Date"
                                    name="payment_date"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select Payment Date',
                                        },
                                    ]}
                                >
                                    <DatePickerTKG />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    name="work_days"
                                    label="Work Days"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input work days',
                                        },
                                    ]}>
                                    <InputTKG form={formUpdate} />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    name="amount"
                                    label="Amount"
                                >
                                    <InputNumberTKG
                                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        form={formUpdate}
                                        readOnly
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item name="currency" label="Currency">
                                    <InputTKG readOnly form={formUpdate} />
                                </Form.Item>
                            </Col>

                            <Col span={24}>
                                <CardFields>
                                    <Divider orientation="left" orientationMargin="0">
                                        <Title level={4} style={{ margin: 'auto' }}>
                                            Add More Payments
                                        </Title>{' '}
                                    </Divider>
                                    <Col span={24}>
                                        <Title level={5} className="title-Form-payment">
                                            KPI Bonus
                                        </Title>

                                        <Form.List name="kpi_bonus">
                                            {(fields, { add, remove }) => (
                                                <>
                                                    {fields.map((field, index) => (
                                                        <Row gutter={16} key={index}>
                                                            <Col span={11}>
                                                                <Form.Item
                                                                    label="Title"
                                                                    name={[field.name, 'title']}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Missing title',
                                                                        },
                                                                    ]}
                                                                >
                                                                    <InputTKG placeholder="Title" form={formUpdate} />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={11}>
                                                                <Form.Item
                                                                    label="Amount"
                                                                    name={[field.name, 'amount']}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Missing amount',
                                                                        },
                                                                    ]}
                                                                >
                                                                    <InputNumberTKG
                                                                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                        placeholder="Amount"
                                                                        form={formUpdate}
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={2}>
                                                                <MinusCircleOutlined className="minus-icon" onClick={() => remove(field.name)} />
                                                            </Col>
                                                        </Row>
                                                    ))}
                                                    <Button
                                                        type="dashed"
                                                        onClick={() => {
                                                            Object.keys(userSelect).length !== 0
                                                                ? add()
                                                                : scollToUser('warning', 'Please select User First!');
                                                        }}
                                                        block
                                                        icon={<PlusOutlined />}
                                                    >
                                                        Add field KPI Bonus
                                                    </Button>
                                                </>
                                            )}
                                        </Form.List>
                                    </Col>
                                    <Col span={24}>
                                        <Title level={5} className="title-Form-payment">
                                            Overtime
                                        </Title>

                                        <Form.List name="overtime">
                                            {(fields, { add, remove }) => (
                                                <>
                                                    {fields.map((field, index) => (
                                                        <Row gutter={16} key={index}>
                                                            <Col span={23}>
                                                                <Row gutter={16}>
                                                                    <Col span={12} md={12} lg={6}>
                                                                        <Form.Item
                                                                            label="Title"
                                                                            name={[field.name, 'title']}
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: 'Missing title',
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <InputTKG placeholder="Title" form={formUpdate} />
                                                                        </Form.Item>
                                                                    </Col>

                                                                    <Col span={12} md={12} lg={4}>
                                                                        <Form.Item
                                                                            label={dataOverTime && dataOverTime[index]?.typeLabel ? dataOverTime[index]?.typeLabel : 'Type'}
                                                                            name={[field.name, 'type']}
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: 'Missing type',
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <SelectTKG options={typeOverTime} onChange={(e) => handleChangeType(e, field.key, index)} />
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col span={8} md={8} lg={4}>
                                                                        <Form.Item
                                                                            label="Hours"
                                                                            name={[field.name, 'hours']}
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: 'Missing hours',
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <InputNumberTKG
                                                                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                                placeholder="Hours"
                                                                                form={formUpdate}
                                                                                onChange={(e) => onChangeHours(e, field.key, field.name, index)}
                                                                            />
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col span={8} md={8} lg={4}>
                                                                        <Form.Item
                                                                            label="Date"
                                                                            name={[field.name, 'date']}
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: 'Missing date',
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <DatePickerTKG form={formUpdate} />
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col span={8} md={8} lg={4}>
                                                                        <Form.Item label="Result" form={formUpdate} name={[field.name, 'hours_amount']}>
                                                                            <InputNumberTKG
                                                                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                                placeholder="result"
                                                                                readOnly
                                                                            />
                                                                        </Form.Item>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col span={1} style={{ alignSelf: 'center' }}>
                                                                <MinusCircleOutlined className="minus-icon" onClick={() => remove(field.name)} />
                                                            </Col>
                                                        </Row>
                                                    ))}
                                                    <Button
                                                        type="dashed"
                                                        onClick={() => {
                                                            Object.keys(userSelect).length !== 0
                                                                ? add()
                                                                : scollToUser('warning', 'Please select User First!');
                                                        }}
                                                        block
                                                        icon={<PlusOutlined />}
                                                    >
                                                        Add field Overtime
                                                    </Button>
                                                </>
                                            )}
                                        </Form.List>
                                    </Col>
                                    {claimAll.length > 0 ?
                                        <Col span={24}>
                                            <Title level={5} className="title-Form-payment">
                                                Claim
                                            </Title>

                                            <Form.List name="claim">
                                                {(fields, { add, remove }) => (
                                                    <>
                                                        {fields.map((field, index) => (
                                                            <Row gutter={16} key={index}>
                                                                <Col span={12}>
                                                                    <Form.Item
                                                                        label="Title"
                                                                        name={[field.name, 'title']}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: 'Missing claim',
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <SelectTKG
                                                                            showSearch
                                                                            options={claimAll}
                                                                            form={formUpdate}
                                                                            onChange={(e) => handleChangeClaim(e, field.key, index)}
                                                                            filterOption={(input, option) =>
                                                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                                            }
                                                                        />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col span={10}>
                                                                    <Form.Item
                                                                        label="Amount"
                                                                        name={[field.name, 'amount']}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: 'Missing amount',
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <InputNumberTKG
                                                                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                            placeholder="Amount"
                                                                            form={formUpdate}
                                                                            readOnly
                                                                        />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col span={2}>
                                                                    <MinusCircleOutlined className="minus-icon" onClick={() => { remove(field.name); handleChangeClaim() }} />
                                                                </Col>
                                                            </Row>
                                                        ))}
                                                        <Button
                                                            type="dashed"
                                                            onClick={() => {
                                                                Object.keys(userSelect).length !== 0
                                                                    ? claimAll.length > 0 ? fields.length < claimAll.length ? add() : PopupStatusTKG('warning', 'You have selected all claims. Please create more claims to add the field!') : PopupStatusTKG('warning', 'Please Create Claim Before Add!')
                                                                    : scollToUser('warning', 'Please Select User First!');
                                                            }}
                                                            block
                                                            icon={<PlusOutlined />}
                                                        >
                                                            Add field Claim
                                                        </Button>
                                                    </>
                                                )}
                                            </Form.List>
                                        </Col>
                                        : <></>}
                                    <Col span={24}>
                                        <Title level={5} className="title-Form-payment">
                                            Other
                                        </Title>

                                        <Form.List name="other">
                                            {(fields, { add, remove }) => (
                                                <>
                                                    {fields.map(({ key, name, ...restField }) => (
                                                        <Row gutter={16} key={key}>
                                                            <Col span={22}>
                                                                <Row gutter={16}>
                                                                    <Col span={12} md={12} lg={6}>
                                                                        <Form.Item
                                                                            {...restField}
                                                                            label="Title"
                                                                            name={[name, 'title']}
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: 'Missing other',
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <InputTKG placeholder="Title" form={formUpdate} />
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col span={12} md={12} lg={6}>
                                                                        <Form.Item
                                                                            label="Date"
                                                                            name={[name, 'date']}
                                                                        >
                                                                            <DatePickerTKG form={formUpdate} />
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col span={12} md={12} lg={6}>
                                                                        <Form.Item
                                                                            {...restField}
                                                                            label="Hours"
                                                                            name={[name, 'hours']}
                                                                        >
                                                                            <InputNumberTKG
                                                                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                                placeholder="Hours"
                                                                                form={formUpdate}
                                                                            />
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col span={12} md={12} lg={6}>
                                                                        <Form.Item
                                                                            {...restField}
                                                                            label="Amount"
                                                                            name={[name, 'amount']}
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: 'Missing amount',
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <InputNumberTKG
                                                                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                                placeholder="Amount"
                                                                                form={formUpdate}
                                                                            />
                                                                        </Form.Item>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col span={2}>
                                                                <MinusCircleOutlined className="minus-icon" onClick={() => remove(name)} />
                                                            </Col>
                                                        </Row>
                                                    ))}
                                                    <Button
                                                        type="dashed"
                                                        onClick={() => {
                                                            Object.keys(userSelect).length !== 0
                                                                ? add()
                                                                : scollToUser('warning', 'Please select User First!');
                                                        }}
                                                        block
                                                        icon={<PlusOutlined />}
                                                    >
                                                        Add field Other
                                                    </Button>
                                                </>
                                            )}
                                        </Form.List>
                                    </Col>
                                </CardFields>
                            </Col>

                            <Col span={24}>
                                <CardFields>
                                    <Divider orientation="left" orientationMargin="0">
                                        <Title level={4} style={{ margin: 'auto' }}>
                                            Less
                                        </Title>{' '}
                                    </Divider>
                                    {finesAll.length > 0 ?

                                        <Col span={24}>
                                            <Title level={5} className="title-Form-payment">
                                                Fines
                                            </Title>

                                            <Form.List name="fine">
                                                {(fields, { add, remove }) => (
                                                    <>
                                                        {fields.map((field, index) => (
                                                            <Row gutter={16} key={index}>
                                                                <Col span={11}>
                                                                    <Form.Item
                                                                        label="Title"
                                                                        name={[field.name, 'title']}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: 'Missing fine',
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <SelectTKG
                                                                            showSearch
                                                                            options={finesAll}
                                                                            form={formUpdate}
                                                                            onChange={(e) => handleChangeFines(e, field.key, index)}
                                                                            filterOption={(input, option) =>
                                                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                                            }
                                                                        />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col span={11}>
                                                                    <Form.Item
                                                                        label="Amount"
                                                                        name={[field.name, 'amount']}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: 'Missing amount',
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <InputNumberTKG
                                                                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                            placeholder="Amount"
                                                                            form={formUpdate}
                                                                            readOnly
                                                                        />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col span={2}>
                                                                    <MinusCircleOutlined className="minus-icon" onClick={() => { remove(field.name); handleChangeFines() }} />
                                                                </Col>
                                                            </Row>
                                                        ))}
                                                        <Button
                                                            type="dashed"
                                                            onClick={() => {
                                                                Object.keys(userSelect).length !== 0
                                                                    ? finesAll.length > 0 ? fields.length < finesAll.length ? add() : PopupStatusTKG('warning', 'You have selected all fines. Please create more fines to add the field!') : PopupStatusTKG('warning', 'Please Create Fines Before Add!')
                                                                    : scollToUser('warning', 'Please Select User First!');
                                                            }}
                                                            block
                                                            icon={<PlusOutlined />}
                                                        >
                                                            Add field Fine
                                                        </Button>
                                                    </>
                                                )}
                                            </Form.List>
                                        </Col>
                                        : <></>}
                                    <Col span={24}>
                                        <Title level={5} className="title-Form-payment">
                                            Tax
                                        </Title>

                                        <Form.List name="less">
                                            {(fields, { add, remove }) => (
                                                <>
                                                    {fields.map(({ key, name, ...restField }) => (
                                                        <Row gutter={16} key={key}>
                                                            <Col span={11}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    label="Title"
                                                                    name={[name, 'title']}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Missing less',
                                                                        },
                                                                    ]}
                                                                >
                                                                    <InputTKG placeholder="Title" form={formUpdate} />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={11}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    label="Amount"
                                                                    name={[name, 'amount']}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Missing amount',
                                                                        },
                                                                    ]}
                                                                >
                                                                    <InputNumberTKG
                                                                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                        placeholder="Amount"
                                                                        form={formUpdate}
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={2}>
                                                                <MinusCircleOutlined className="minus-icon" onClick={() => remove(name)} />
                                                            </Col>
                                                        </Row>
                                                    ))}
                                                    <Button
                                                        type="dashed"
                                                        onClick={() => {
                                                            Object.keys(userSelect).length !== 0
                                                                ? add()
                                                                : scollToUser('warning', 'Please select User First!');
                                                        }}
                                                        block
                                                        icon={<PlusOutlined />}
                                                    >
                                                        Add field Tax
                                                    </Button>
                                                </>
                                            )}
                                        </Form.List>
                                    </Col>
                                    <Col span={24}>
                                        <Title level={5} className="title-Form-payment">
                                            Other
                                        </Title>

                                        <Form.List name="other_less">
                                            {(fields, { add, remove }) => (
                                                <>
                                                    {fields.map(({ key, name, ...restField }) => (
                                                        <Row gutter={16} key={key}>
                                                            <Col span={22}>
                                                                <Row gutter={16}>
                                                                    <Col span={12} md={12} lg={6}>
                                                                        <Form.Item
                                                                            {...restField}
                                                                            label="Title"
                                                                            name={[name, 'title']}
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: 'Missing other',
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <InputTKG placeholder="Title" form={formUpdate} />
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col span={12} md={12} lg={6}>
                                                                        <Form.Item
                                                                            label="Date"
                                                                            name={[name, 'date']}
                                                                        >
                                                                            <DatePickerTKG form={formUpdate} />
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col span={12} md={12} lg={6}>
                                                                        <Form.Item
                                                                            {...restField}
                                                                            label="Hours"
                                                                            name={[name, 'hours']}
                                                                        >
                                                                            <InputNumberTKG
                                                                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                                placeholder="Hours"
                                                                                form={formUpdate}
                                                                            />
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col span={12} md={12} lg={6}>
                                                                        <Form.Item
                                                                            {...restField}
                                                                            label="Amount"
                                                                            name={[name, 'amount']}
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: 'Missing amount',
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <InputNumberTKG
                                                                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                                placeholder="Amount"
                                                                                form={formUpdate}
                                                                            />
                                                                        </Form.Item>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col span={2}>
                                                                <MinusCircleOutlined className="minus-icon" onClick={() => remove(name)} />
                                                            </Col>
                                                        </Row>
                                                    ))}
                                                    <Button
                                                        type="dashed"
                                                        onClick={() => {
                                                            Object.keys(userSelect).length !== 0
                                                                ? add()
                                                                : scollToUser('warning', 'Please select User First!');
                                                        }}
                                                        block
                                                        icon={<PlusOutlined />}
                                                    >
                                                        Add field Other
                                                    </Button>
                                                </>
                                            )}
                                        </Form.List>
                                    </Col>
                                </CardFields>
                            </Col>

                            <Col span={24}>
                                <Form.Item form={formUpdate} name="note" label="Note">
                                    <TextEditorTKG placeholder="Content here" form={formUpdate} />
                                </Form.Item>
                            </Col>

                            <Col span={24}>
                                <Button type="primary" className="margin-08" htmlType="submit" loading={loading}>
                                    Submit
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </CardContent>
            </ModalFormTKG>
        </>
    );
};

export default Update;
