import React, { useContext, useEffect, useState } from 'react';
import { Typography } from 'antd';
import Context from '../../utils/context';
import { Timer } from '../../components/clockInClockOut/Timer';
const { Title } = Typography;

const TimerClock = () => {
    const context = useContext(Context);

    const [dateState, setDateState] = useState(new Date());
    useEffect(() => {
        setInterval(() => {
            setDateState(new Date());
        }, 1000);
    }, []);
    return (
        <>
            <div
                style={{
                    background: '#fff',
                    boxShadow: '0 1px 2px 0 rgb(0 0 0 / 3%), 0 1px 6px -1px rgb(0 0 0 / 2%), 0 2px 4px 0 rgb(0 0 0 / 2%)',
                    padding: 10,
                }}
            >
                <Title
                    level={1}
                    style={{
                        margin: 0,
                        // opacity: 0.7,
                        height: 40,
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}
                >
                    <span
                        style={{ cursor: 'pointer', margin: 'auto 0', fontSize: '1.8rem' }}
                        onClick={() => {
                            context.showModal();
                        }}
                    >
                        {dateState.toLocaleString('en-US', {
                            hour: 'numeric',
                            minute: 'numeric',
                            hour12: true,
                        })}
                    </span>
                </Title>
            </div>
            <Timer
                isOpenModal={context?.isOpenModal}
                handleCancel={() => {
                    context.hideModal();
                }}
            />
        </>
    );
};

export default TimerClock;
