import { useState } from 'react';
import DetailUserPayment from './detailUserPayment';
import ListUserPayment from './listUserPayment';

const Payment = () => {
    const userRole = JSON.parse(sessionStorage.getItem('user'))?.role_id;
    const [openDrawer, setOpenDrawer] = useState(false);
    return <>{userRole === 1 ? <ListUserPayment openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} /> : <DetailUserPayment />}</>;
};
export default Payment;
