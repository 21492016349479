import axios from 'axios';
import { encode } from 'js-base64';


const defaultHeader = { Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`, timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone }

const instance = axios.create({
  baseURL: process.env.TIME_REPORT_API_URL,
  timeout: 5000000,
  headers: defaultHeader,
});

instance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {

    if (error?.response?.data?.meta?.status === 401 && error?.response?.data?.meta?.message === 'Token Expired !') {
      const config = error.response.config || {}
      const iframe = document.getElementById('iframe-sso');
      iframe.onload = function () {
        iframe.contentWindow.postMessage({ type: 'refresh-token', token: error.response.data.response.token }, iframe.getAttribute('base-src'));
      }
      if (iframe.getAttribute('base-src')) {
        iframe.setAttribute('src', `${iframe.getAttribute('base-src')}verify/${encode(error.response.data.response.token)}?from-iframe=true`)
      }
      config.headers.Authorization = 'Bearer ' + error.response.data.response.token;
      return instance(config);
    } else if (error?.response?.data?.meta?.status === 401) {
      if (process.env.REACT_APP_ENV === "production") {
        window.location.replace(process.env.REACT_APP_DOMAIN_SSO);

      }
    }
  }
);

export async function getAuth(url, data = {}, headers = defaultHeader) {
  const result = await instance
    .get(url, {
      headers: { ...headers, Authorization: `Bearer ${sessionStorage.getItem("accessToken")}` },
      params: data,
    });
  return result;
}

export async function postAuth(url, data, headers = defaultHeader) {
  const result = await instance
    .post(url, data, {
      headers: { ...headers, Authorization: `Bearer ${sessionStorage.getItem("accessToken")}` },
    })
  return result;
}

export async function postFileAuth(url, data, headers = defaultHeader) {
  const result = await instance
    .post(url, data, {
      headers: { ...headers, Authorization: `Bearer ${sessionStorage.getItem("accessToken")}` },
    })

  return result;
}

export async function putAuth(url, data, headers = defaultHeader) {
  const result = await instance
    .put(url, data, {
      headers: { ...headers, Authorization: `Bearer ${sessionStorage.getItem("accessToken")}` },
    })
  return result;
}

export async function deleteAuth(url, headers = defaultHeader) {
  const result = await axios
    .delete(url, {
      headers: { ...headers, Authorization: `Bearer ${sessionStorage.getItem("accessToken")}` },
    })
  return result;
}



