export const priorityClaims = [
    { value: 1, label: 'Low', color: '#b7c2c5' },
    { value: 2, label: 'Medium', color: '#0288d1' },
    { value: 3, label: 'High', color: '#eb3a34' },
];

export const currencyAll = [
    { value: 'AUD', label: 'AUD' },
    { value: 'USD', label: 'USD' },
    { value: 'VND', label: 'VND' },
];

export const typePayments = [
    { value: 1, label: 'Wages' },
    { value: 2, label: 'KPI Bonus' },
    { value: 3, label: 'Work relate expensive' },
    { value: 4, label: 'Overtime' },
];

export const positions = [
    { value: 'manages', label: 'Manages' },
    { value: 'staff', label: 'Staff' },
];

export const typeClaims = [
    { value: 1, label: 'value 1' },
    { value: 2, label: 'value 2' },
];

export const statusAll = [
    { value: 'pending', label: 'Pending', color: 'red' },
    { value: 'executed', label: 'Executed', color: 'green' },
];

export const format_money = (money) => Intl.NumberFormat('en-US').format(money);

export const typeOverTime = [
    { value: 'normal', label: 'Normal' },
    { value: 'weekend', label: 'Weekend' },
    { value: 'holiday', label: 'Holiday' },
];
