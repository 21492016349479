/* eslint-disable jsx-a11y/iframe-has-title */
import './App.scss';
import { Layout, Drawer, ConfigProvider } from 'antd';
import React, { useEffect, useReducer, useState } from 'react';
import { Outlet } from 'react-router-dom';
import LayoutHeader from './components/layouts/header';
import LayoutSider from './components/layouts/sidebar';
import Context from './utils/context';
import * as ACTIONS from './store/actions/actions';
import * as TimerReducer from './store/reducers/timerReducer';
import TimerClock from './components/layouts/TimerClock';
import { encode } from 'js-base64';

const { Content, Sider } = Layout;

const bc = new BroadcastChannel('twt');
bc.onmessage = (eventMessage) => {
    const data = eventMessage.data;
    if (data.type === 'refresh-token') {
        sessionStorage.setItem('accessToken', data.token);
    } else if (data.type === 'logout') {
        window.location.href = process.env.REACT_APP_DOMAIN_SSO;
    }
};

window.onload = function () {
    window.addEventListener(
        'message',
        function (event) {
            const { data } = event;
            bc.postMessage(data);
        },
        false,
    );
};

function App() {
    const [nameHeader, setNameHeader] = useState('');
    const [stateTimerReducer, dispatchTimerReducer] = useReducer(TimerReducer.TimerReducer, TimerReducer.initialState);

    useEffect(() => {
        const token = sessionStorage.getItem('accessToken');
        if (!token) {
            window.location.replace(process.env.REACT_APP_DOMAIN_SSO);
        } else {
            if (!nameHeader)
                setTimeout(() => {
                    setNameHeader(JSON.parse(sessionStorage.getItem('user'))?.first_name);
                }, 500);
        }
    }, [nameHeader]);

    const [keyItem, setKeyItem] = React.useState(1);
    const [visible, setVisible] = React.useState(false);
    const openDrawer = () => setVisible(!visible);

    const onChangeItem = async (index, item) => {
        setKeyItem(index);
    };

    const handleShowModal = () => {
        dispatchTimerReducer(ACTIONS.showModal());
    };

    const handleHideModal = () => {
        dispatchTimerReducer(ACTIONS.hideModal());
    };
    return (
        <Context.Provider
            value={{
                isOpenModal: stateTimerReducer.isOpenModal,
                showModal: () => handleShowModal(),
                hideModal: () => handleHideModal(),
            }}
        >
            <ConfigProvider
                theme={{
                    token: {
                        fontFamily: 'Nunito Sans, sans-serif',
                        colorPrimary: '#0288d1',
                        colorBorder: '#e3e6ef',
                        borderRadius: 8,
                        colorTextPlaceholder: '#b7c2c5',
                        controlHeightLG: 42,
                        fontSizeLG: 14,
                    },
                }}
            >
                <Layout>
                    {!JSON.parse(sessionStorage.getItem('from-iframe')) && sessionStorage.getItem('from-iframe') != null && (
                        <div style={{ display: 'none' }}>
                            <iframe
                                id="iframe-sso"
                                base-src={`${process.env.REACT_APP_DOMAIN_SSO}`}
                                src={`${process.env.REACT_APP_DOMAIN_SSO}verify/${encode(sessionStorage.getItem('accessToken'))}?from-iframe=true`}
                            ></iframe>
                        </div>
                    )}
                    <Drawer title={false} placement={'left'} closable={false} onClose={() => setVisible(false)} open={visible} key={'left'} width={270}>
                        <Sider className="siderTablet" width={270}>
                            <LayoutSider keyItem={keyItem} onChangeItem={onChangeItem} />
                        </Sider>
                    </Drawer>
                    <Sider className="sider" width={270}>
                        <LayoutSider keyItem={keyItem} onChangeItem={onChangeItem} />
                    </Sider>
                    <Layout className="site-layout">
                        <LayoutHeader openDrawer={openDrawer} visible={visible} name={nameHeader} />
                        <TimerClock />
                        <Content className="site-layout__content">
                            <Outlet />
                        </Content>
                    </Layout>
                </Layout>
            </ConfigProvider>
        </Context.Provider>
    );
}

export default App;
