import { ArrowLeftOutlined, ClockCircleOutlined } from '@ant-design/icons';
import { Button, Card, Col, Row, Space, Tag } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { list } from '../../api/service/claimService';
import { listUser } from '../../api/service/userService';
import { format_money, statusAll } from '../../assets/constants';
import TableCustom from '../../components/table';
import Create from './create';
import Detail from './detail';

const DetailUserClaims = () => {
    const [loading, setLoading] = useState(false);
    const [claimsPage, setClaimsPage] = useState({});
    const [pageChange, setPageChange] = useState(1);
    const [data, setData] = useState([]);
    const [getListUser, setGetListUser] = useState([]);
    const navigate = useNavigate();
    const userRole = JSON.parse(sessionStorage.getItem('user'))?.role_id;

    const columns = [
        {
            title: 'Title',
            dataIndex: 'title',
        },
        // {
        //     title: 'Details',
        //     dataIndex: 'detail',
        //     render: (detail) => <div dangerouslySetInnerHTML={{ __html: detail }} />,
        // },
        // {
        //     title: 'Type Claims',
        //     dataIndex: 'type',
        //     align: 'center',
        //     render: (type) => <Tag color={type === 1 ? '#0288d1' : type === 2 ? '#eb3a34' : '#b7c2c5'} style={{ textAlign: 'center', fontWeight: 700 }}>{type}</Tag>,
        // },
        {
            title: `Amount (${JSON.parse(sessionStorage.getItem('user'))?.currency})`,
            dataIndex: 'amount',
            align: 'center',
            render: (amount) => format_money(amount),
        },
        {
            title: 'Date Claims',
            dataIndex: 'date_claims',
            align: 'center',
            render: (date_claims) => (
                <Tag icon={<ClockCircleOutlined />} color="default">
                    {moment(date_claims).format('DD-MMM-YYYY')}
                </Tag>
            ),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            align: 'center',
            render: (status) => (
                <Tag color={statusAll.filter((sts) => sts?.value === status)[0]?.color} style={{ textAlign: 'center' }}>
                    {statusAll.filter((sts) => sts?.value === status)[0]?.label}
                </Tag>
            ),
        },
        {
            title: 'Action',
            dataIndex: 'action',
            align: 'center',
            render: (action) => (
                <Space size={16}>
                    {/* {JSON.parse(sessionStorage.getItem('user'))?.role_id === 1 ? (
                        <Update id={action?.id} dataUser={getListUser} handleCallback={handleParentChange} page={pageChange} />
                    ) : (
                        <></>
                    )} */}
                    <Detail id={action?.id} />
                </Space>
            ),
        },
    ];

    const fetchList = async (page) => {
        setLoading(true);

        var url = window.location.pathname;
        var user_id = url.split('/')[3];
        var month = url.substring(url.lastIndexOf('/') + 1);
        const listClaims = await list({ user_id: user_id, month: month, page: page });

        if (listClaims?.data?.meta?.status === 200) {
            setClaimsPage({ total: listClaims?.data?.response?.total, current: listClaims?.data?.response?.current_page });
            setData(
                listClaims?.data?.response?.data?.map((e) => {
                    return {
                        key: e?.id,
                        title: e?.title,
                        detail: e?.detail,
                        // type: e?.type,
                        currency: e?.currency,
                        amount: e?.amount,
                        date_claims: e?.date_claims,
                        status: e?.status,
                        action: e,
                    };
                }),
            );
            setLoading(false);
        }

        if (userRole === 1) {
            const getUser = await listUser();
            if (getUser?.data?.meta?.status === 200) {
                let getListUsers = [];
                getListUsers = getUser?.data?.response?.map((e) => {
                    return { value: e?.id, label: e?.first_name + ' ' + e?.surname, currency: e?.currency };
                });
                setGetListUser(getListUsers);
            }
        }
    };

    const handleParentChange = (e) => {
        fetchList(e);
    };

    const onChange = (pagination) => {
        setPageChange(pagination.current);
        fetchList(pagination.current);
    };

    const goBack = () => {
        navigate(-1);
    };

    useEffect(() => {
        fetchList(pageChange);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageChange]);

    return (
        <>
            <Card>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Space>
                            <Button type="default" icon={<ArrowLeftOutlined />} onClick={goBack}>
                                Back
                            </Button>
                            {JSON.parse(sessionStorage.getItem('user'))?.role_id === 1 ? (
                                <Create dataUser={getListUser} handleCallback={handleParentChange} page={pageChange} />
                            ) : (
                                <></>
                            )}
                        </Space>
                    </Col>
                    <Col span={24}>
                        <TableCustom
                            size="middle"
                            loading={loading}
                            columns={columns}
                            dataSource={data}
                            onChange={onChange}
                            pagination={{ total: claimsPage?.total, current: claimsPage?.current }}
                            scroll={{
                                x: 1024,
                            }}
                        />
                    </Col>
                </Row>
            </Card>
        </>
    );
};

export default DetailUserClaims;
