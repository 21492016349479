import { Table } from 'antd';
import styled from 'styled-components';

const StyleTable = styled(Table)`
    .ant-table-thead > tr > th {
        font-weight: 700;
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
        border-bottom: 2px solid #000;
    }
    border-radius: 0px 0px 10px 10px;
    tr:nth-child(even) {
        background-color: rgb(248 249 251);
    }
`;

const StyleTwoLinesBorder = styled(Table)`
    .ant-table-thead > tr > th {
        font-weight: 700;
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
        border-bottom: 1px solid #000;
    }
    .ant-table-thead > tr > th:after {
        content: '';
        border-bottom: 1px solid #000;
        width: 100%;
        position: absolute;
        bottom: -4px;
        left: 0;
    }

    .ant-table-tbody > tr > td {
        border-bottom: 1px solid #000;
    }
    .ant-table-tbody > tr > td:after {
        content: '';
        border-bottom: 1px solid #000;
        width: 100%;
        position: absolute;
        bottom: -53px;
        left: 0;
    }
    border-radius: 0px 0px 10px 10px;
    tr:nth-child(even) {
        background-color: rgb(248 249 251);
    }
`;

const StyleTwoLinesBorderHeader = styled(Table)`
    .ant-table-thead > tr > th {
        // background-color: #e3e6ef;
        // border-color: rgb(241, 242, 246);
        // color: rgb(10, 10, 10);
        font-weight: 700;
        padding-top: .5rem !important;
        padding-bottom: .5rem !important;
        border-bottom: 1px solid #000;
    }
    .ant-table-thead > tr > th:after {
        content: '';
        border-bottom: 1px solid #000;
        width: 100%;
        position: absolute;
        bottom: -4px;
        left: 0;
    }

    border-radius: 0px 0px 10px 10px;
    tr:nth-child(even) {
        background-color: rgb(248 249 251);
    }
`;

const TableCustom = (props) => {
    const propOverwrite = { ...props };
    return <StyleTable {...propOverwrite} />;
};

const TableBoderTwoLines = (props) => {
    const propOverwrite = { ...props };
    return <StyleTwoLinesBorder {...propOverwrite} />;
};

const TableBoderTwoLinesHeader = (props) => {
    const propOverwrite = { ...props };
    return <StyleTwoLinesBorderHeader {...propOverwrite} />;
};

const TablePadding = styled(Table)`
    .ant-table-thead > tr > th {
        background-color: rgb(248, 249, 251);
        border-color: rgb(241, 242, 246);
        color: rgb(10, 10, 10);
        font-weight: 600;
        padding-right: 0;
    }

    .ant-table-tbody > tr > td {
        padding: 6px 0px 6px 6px !important;
    }
`;
export default TableCustom;
export { TablePadding, TableBoderTwoLines, TableBoderTwoLinesHeader };
