import ListMonthClaims from './listMonthClaims';
import ListUserClaims from './listUserClaims';

const Claims = () => {
    return (
        <>
            {JSON.parse(sessionStorage.getItem('user'))?.role_id === 1 ? <ListUserClaims /> : <ListMonthClaims />}
        </>
    );
};

export default Claims;
