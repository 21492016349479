import { Menu } from 'antd';
import React, { useEffect, useState } from 'react';
import menu from '../../router';

const LayoutSider = () => {
    const [current, setCurrent] = useState('');
    const onClick = (e) => {
        setCurrent(e.key);
    };
    useEffect(() => {
        if (window.location.pathname.split('/')[1] === 'verify') {
            return setCurrent('payments');
        }
        if (window.location.pathname.split('/')[1] !== 'payments') {
            return setCurrent(window.location.pathname.split('/')[1]);
        }
        return setCurrent('payments');
    }, []);

    return (
        <>
            <Menu
                defaultSelectedKeys={['1']}
                mode="inline"
                defaultOpenKeys={[current]}
                selectedKeys={[current]}
                items={menu}
                onClick={onClick}
            />
        </>
    );
};

export default LayoutSider;
