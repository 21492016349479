import { FolderOutlined } from '@ant-design/icons';
import { Button, Space, Typography } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { listUpcoming } from '../../api/service/paymentsService';
import { format_money } from '../../assets/constants';
import TableCustom from '../../components/table';
import Update from './update';
const { Title } = Typography;
const UpcomingPayments = ({ id, clone, user, userSelect }) => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const navigate = useNavigate();

    const fetchList = async (page) => {
        setLoading(true);
        await listUpcoming(id).then((res) => {
            if (res?.data?.meta?.status === 200) {
                setData(
                    res?.data?.response?.map((e) => {
                        return {
                            key: e?.id,
                            action: e,
                            ...e,
                        };
                    }),
                );
            }
            setLoading(false);
        });
    };

    useEffect(() => {
        fetchList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, clone]);

    const handleParentCallBack = () => {
        fetchList();
    }

    const columns = [
        {
            title: 'Title',
            dataIndex: 'title',
        },
        {
            title: 'Work Period',
            dataIndex: 'work_period',
            render: (work_period) => work_period && moment(JSON.parse(work_period)[0]).format('DD-MMM-YYYY') + ' To: ' + moment(JSON.parse(work_period)[1]).format('DD-MMM-YYYY'),
        },
        // {
        //     title: 'Currency',
        //     dataIndex: 'currency',
        //     align: 'center',
        // },
        {
            title: `Amount (${userSelect?.currency ? userSelect?.currency : JSON.parse(sessionStorage.getItem('user'))?.currency})`,
            dataIndex: 'amount',
            align: 'center',
            render: (amount) => <b>{format_money(amount)}</b>,
        },
        {
            title: 'Payment Date',
            dataIndex: 'payment_date',
            align: 'center',
            render: (payment_date) => moment(payment_date).format('DD-MMM-YYYY'),
        },
        {
            title: 'Action',
            dataIndex: 'action',
            align: 'center',
            render: (action) => (
                <Space>
                    {user?.role_id === 1 && action?.paid === 0 ?
                        <Update dataPayment={action} handleCallBack={handleParentCallBack} />
                        :
                        <></>
                    }
                    <Button type="primary" className="btnTKGAction" icon={<FolderOutlined />} ghost onClick={() => navigate(`/payments/detail/${action?.id}`)}>
                        View
                    </Button>
                </Space>
            ),
        },
    ];

    return (
        <>
            <Title level={3}>Upcoming Payments</Title>
            <TableCustom
                loading={loading}
                columns={columns}
                dataSource={data}
                pagination={false}
                scroll={{
                    x: 700,
                }}
            />
        </>
    );
};
export default UpcomingPayments;
