import { getAuth, postAuth, putAuth, deleteAuth } from '../authService';

const API_URL = process.env.REACT_APP_API_URL;

const endPoint = {
    list: 'api/fines',
    listAllTargetMonth: 'api/fines/index-month',
    listMonth: 'api/fines/list-month/',
    detail: 'api/fines/detail/',
    create: 'api/fines/store',
    update: 'api/fines/update/',
    delete: 'api/fines/delete/',
    listAll: 'api/fines/list-all/',
};

export const list = async (params) => {
    return await getAuth(API_URL + endPoint.list, params);
};

export const listAll = async (userId) => {
    return await getAuth(API_URL + endPoint.listAll + userId);
};

export const listAllTargetMonth = async (params) => {
    return await getAuth(API_URL + endPoint.listAllTargetMonth, params);
};

export const listMonth = async (userId, params) => {
    return await getAuth(API_URL + endPoint.listMonth + userId, params);
};

export const detail = async (data) => {
    return await getAuth(API_URL + endPoint.detail + data);
};

export const create = async (data) => {
    return await postAuth(API_URL + endPoint.create, data);
};

export const update = async (id, data) => {
    return await putAuth(API_URL + endPoint.update + id, data);
};

export const deleteFines = async (id) => {
    return await deleteAuth(API_URL + endPoint.delete + id);
};
