import { Tabs } from 'antd';
import styled from 'styled-components';

const StyleTabs = styled(Tabs)`
    .ant-tabs-nav {
        margin: 0;
        .ant-tabs-tab-active {
            background-color: #154d76;
            .ant-tabs-tab-btn {
                color: #fff;
                font-weight: 700;
            }
        }
        &:before {
            border: none;
        }
    }
`;

const TabsCustom = (props) => {
    const propOverwrite = { ...props };
    return <StyleTabs {...propOverwrite} />;
};

export default TabsCustom;
