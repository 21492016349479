import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Col, Row, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { SelectTKG } from 'tkg-composite-ui';
import { listUser } from '../../api/service/userService';
import HistoryPayments from './history';
import UpcomingPayments from './upcoming';

const DetailUserPayment = () => {
    const params = useParams();
    const userId = JSON.parse(sessionStorage.getItem('user'))?.id;
    const userRole = JSON.parse(sessionStorage.getItem('user'))?.role_id;
    const navigate = useNavigate();
    const [listUserId, setListUserId] = useState([]);
    const [loading, setLoading] = useState(true);
    const [userCurrent, setUserCurrent] = useState(+params.id ? +params.id : userId);
    const [clone, setClone] = useState(false);

    useEffect(() => {
        if (userRole === 1) {

            const getListUser = async () => {
                return await listUser();
            };

            getListUser().then((response) => {
                if (response?.data?.meta?.status === 200) {
                    const dataUser = response?.data?.response.map((e) => {
                        return {
                            value: e?.id,
                            label: e?.first_name + ' ' + e?.surname,
                            ...e,
                        };
                    });
                    setListUserId(dataUser);
                    setLoading(false);
                }
            });
        }
        setLoading(false);
        setClone(false);
    }, [userCurrent, userRole, clone]);

    const showListPayment = (e) => {
        setUserCurrent(e);
        navigate(`/payments/${e}`);
    }

    const handleBack = () => {
        return navigate('/payments');
    };

    const handleCallBackClone = (e) => {
        setClone(e);
    }

    return (
        <Row lign="middle" justify="center">
            {loading ?
                <Spin size='large'></Spin>
                :
                <Col span={20}>
                    {userRole === 1 && (
                        <Row gutter={[16, 16]}>
                            <Col span={16} lg={18} md={16} xs={24}>
                                <Button icon={<ArrowLeftOutlined />} onClick={handleBack}>
                                    Back
                                </Button>
                            </Col>
                            <Col span={8} lg={6} md={8} xs={24}>
                                <SelectTKG
                                    options={listUserId}
                                    onChange={(e) => showListPayment(e)}
                                    showSearch
                                    value={listUserId.find((e) => (e?.value === userCurrent))?.label}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                />
                            </Col>
                        </Row>
                    )}

                    <UpcomingPayments id={userCurrent} clone={clone} user={listUserId.find((e) => e?.id === userId)} userSelect={listUserId.find((e) => e?.id === userCurrent)} />
                    <HistoryPayments id={userCurrent} handleCallBack={handleCallBackClone} user={listUserId.find((e) => e?.id === userId)} userSelect={listUserId.find((e) => e?.id === userCurrent)} />
                </Col>
            }
        </Row>
    );
};

export default DetailUserPayment;
