const fetchError = ({ response }) => {
    if (Object.keys(response?.data?.response)?.length !== 0) {
        let reason = '';
        Object.keys(response?.data?.response).forEach((element) => {
            reason += `\n${response?.data.response[element]}`;
        });
        return reason;
    } else {
        return response?.data?.meta?.message;
    }
};

export default fetchError;
