import { ClockCircleOutlined } from '@ant-design/icons';
import { Button, Col, Row, Space, Tag, Typography } from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';
import { useState } from 'react';
import styled from 'styled-components';
import { ModalFormTKG } from 'tkg-composite-ui';
import { detail } from '../../api/service/claimService';
import { format_money, statusAll } from '../../assets/constants';
import SpinLoading from '../../components/loading';

const { Text } = Typography;

const CardContent = styled.div`
    background: #fff;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 3%), 0 1px 6px -1px rgb(0 0 0 / 2%), 0 2px 4px 0 rgb(0 0 0 / 2%);
    border-radius: 10px;
    margin-top: 0.2em;
    padding: 1em;
`;

const Detail = ({ id }) => {
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [dataClaims, setDataClaims] = useState({});

    const fecthDetail = async () => {
        const resDetail = await detail(id);
        if (resDetail?.data?.meta?.status === 200) {
            setDataClaims({
                id: resDetail?.data?.response?.id,
                user_id: resDetail?.data?.response?.user_id,
                title: resDetail?.data?.response?.title,
                amount: resDetail?.data?.response?.amount,
                currency: resDetail?.data?.response?.currency,
                date_claims: dayjs(resDetail?.data?.response?.date_claims),
                status: resDetail?.data?.response?.status,
                detail: resDetail?.data?.response?.detail,
            });
            setLoading(false);
        }
    };

    const showDrawer = () => {
        setOpen(true);
        setLoading(true);
        fecthDetail();
    };
    const onClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Button type="primary" onClick={showDrawer}>
                View
            </Button>
            <ModalFormTKG title="Detail Claims" closable={false} onClose={onClose} open={open}>
                <CardContent>
                    {loading ? (
                        <SpinLoading />
                    ) : (
                        <Row className="container" gutter={[24, 24]}>
                            <Col span={24}>
                                <Space>
                                    <Text strong>Title: {dataClaims?.title}</Text>
                                </Space>
                            </Col>
                            <Col span={24} md={12} lg={6}>
                                <Space>
                                    <Text strong>Amount:</Text>
                                    <Typography>{format_money(dataClaims?.amount)}</Typography>
                                </Space>
                            </Col>
                            <Col span={24} md={12} lg={6}>
                                <Space>
                                    <Text strong>Currency:</Text>
                                    <Typography>{dataClaims?.currency}</Typography>
                                </Space>
                            </Col>
                            <Col span={24} md={12} lg={6}>
                                <Space>
                                    <Text strong>Date:</Text>
                                    <Tag icon={<ClockCircleOutlined />} color="default">
                                        {moment(dataClaims?.date_claims).format('DD-MMM-YYYY')}
                                    </Tag>
                                </Space>
                            </Col>
                            <Col span={24} md={12} lg={6}>
                                <Space>
                                    <Text strong>Status:</Text>
                                    <Tag color={statusAll.filter((sts) => sts?.value === dataClaims?.status)[0]?.color} style={{ textAlign: 'center' }}>
                                        {statusAll.filter((sts) => sts?.value === dataClaims?.status)[0]?.label}
                                    </Tag>
                                </Space>
                            </Col>
                            <Col span={24}>
                                <Text strong>Detail:</Text>
                                <Typography dangerouslySetInnerHTML={{ __html: dataClaims?.detail }} />
                            </Col>
                        </Row>
                    )}
                </CardContent>
            </ModalFormTKG>
        </>
    );
};

export default Detail;
