import { ArrowLeftOutlined, ClockCircleOutlined } from '@ant-design/icons';
import { Button, Card, Col, Row, Space, Tag } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { listMonth } from '../../api/service/claimService';
import { listUser } from '../../api/service/userService';
import TableCustom from '../../components/table';
import Create from './create';

const ListMonthClaims = () => {
    const navigate = useNavigate();
    const [getListUser, setGetListUser] = useState([]);
    const [pageChange, setPageChange] = useState(1);
    const [monthPage, setMonthPage] = useState({});
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const userRole = JSON.parse(sessionStorage.getItem('user'))?.role_id;

    const columns = [
        {
            title: 'Date',
            dataIndex: 'month',
            render: (month) => {
                return (
                    <Tag icon={<ClockCircleOutlined />} color="default">
                        {moment(month).format('MMM-YYYY')}
                    </Tag>
                );
            },
        },
        {
            title: 'Action',
            dataIndex: 'action',
            align: 'center',
            render: (action) => {
                return (
                    <Button
                        type="primary"
                        onClick={() => {
                            navigate(`/claims/detail/${action?.user_id}/${action?.month_year}`);
                        }}
                    >
                        View
                    </Button>
                );
            },
        },
    ];

    const fetchList = async (page) => {
        setLoading(true);

        var url = window.location.pathname;
        var user_id = url.substring(url.lastIndexOf('/') + 1);
        const getListMonth = await listMonth(Number(user_id), { page: page });
        if (getListMonth?.data?.meta?.status === 200) {
            setLoading(false);
            setMonthPage({ total: getListMonth?.data?.response?.total, current: getListMonth?.data?.response?.current_page });
            setData(
                getListMonth?.data?.response?.data?.map((e, ind) => {
                    return {
                        key: ind,
                        month: e?.month_year,
                        action: e,
                    };
                }),
            );
        }

        if (userRole === 1) {
            const getUser = await listUser();
            if (getUser?.data?.meta?.status === 200) {
                let getListUsers = [];
                getListUsers = getUser?.data?.response?.map((e) => {
                    return { value: e?.id, label: e?.first_name + ' ' + e?.surname, currency: e?.currency };
                });
                setGetListUser(getListUsers);
            }
        }
    };

    const onChange = (pagination) => {
        setPageChange(pagination.current);
        fetchList(pagination.current);
    };

    const handleParentCallback = () => {
        fetchList(pageChange);
    };

    const goBack = () => {
        navigate(-1);
    };

    useEffect(() => {
        fetchList(pageChange);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageChange]);

    return (
        <>
            <Card>
                <Row className="margin-bottom-16">
                    {JSON.parse(sessionStorage.getItem('user'))?.role_id === 1 && (
                        <Space>
                            <Button type="default" icon={<ArrowLeftOutlined />} onClick={goBack}>
                                Back
                            </Button>
                            <Create dataUser={getListUser} handleCallback={handleParentCallback} />
                        </Space>
                    )}
                </Row>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <TableCustom
                            size="middle"
                            loading={loading}
                            columns={columns}
                            dataSource={data}
                            onChange={onChange}
                            pagination={{ total: monthPage?.total, current: monthPage?.current }}
                        />
                    </Col>
                </Row>
            </Card>
        </>
    );
};

export default ListMonthClaims;
