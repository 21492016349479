import { getAuth, postAuth, deleteAuth } from '../authService';

import dayjs from 'dayjs';

const BASE_API_URL_TIME_WORKOUT = process.env.REACT_APP_API_TIME_AND_REPORT_URL;

const API_CLOCK_IN_CLOCK_OUT = {
    CLOCK_IN: `${BASE_API_URL_TIME_WORKOUT}/api/work-hours/clock-in`,
    CLOCK_OUT: `${BASE_API_URL_TIME_WORKOUT}/api/work-hours/clock-out`,
    BREAK: `${BASE_API_URL_TIME_WORKOUT}/api/work-hours/break`,
    LUNCH_BREAK: `${BASE_API_URL_TIME_WORKOUT}/api/work-hours/lunch-break`,
    RESUME_WORK: `${BASE_API_URL_TIME_WORKOUT}/api/work-hours/resume-work`,
    LOG_TODAY: `${BASE_API_URL_TIME_WORKOUT}/api/work-hours/today`,
    RESET_TIME: `${BASE_API_URL_TIME_WORKOUT}/api/work-hours/clear`,
};

export const handleClockIn = async () => {
    const payload = { request_date_time: dayjs().format('YYYY-MM-DD HH:mm:ss') }
    return await postAuth(`${API_CLOCK_IN_CLOCK_OUT.CLOCK_IN}`, payload);
};
export const handleClockOut = async () => {
    const payload = { request_date_time: dayjs().format('YYYY-MM-DD HH:mm:ss') }
    return await postAuth(`${API_CLOCK_IN_CLOCK_OUT.CLOCK_OUT}`, payload);
};
export const getLogToday = async () => {
    const payload = { request_date_time: dayjs().format('YYYY-MM-DD HH:mm:ss') }
    return await getAuth(`${API_CLOCK_IN_CLOCK_OUT.LOG_TODAY}`, payload);
};
export const handleBreakWork = async () => {
    const payload = { request_date_time: dayjs().format('YYYY-MM-DD HH:mm:ss') }
    return await postAuth(`${API_CLOCK_IN_CLOCK_OUT.BREAK}`, payload);
};
export const handleLunchBreakWork = async () => {
    const payload = { request_date_time: dayjs().format('YYYY-MM-DD HH:mm:ss') }
    return await postAuth(`${API_CLOCK_IN_CLOCK_OUT.LUNCH_BREAK}`, payload);
};
export const handleResumeWork = async () => {
    const payload = { request_date_time: dayjs().format('YYYY-MM-DD HH:mm:ss') }
    return await postAuth(`${API_CLOCK_IN_CLOCK_OUT.RESUME_WORK}`, payload);
};
export const handleResetTime = async () => {
    return await deleteAuth(`${API_CLOCK_IN_CLOCK_OUT.RESET_TIME}`);
};
