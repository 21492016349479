import ListMonthFines from './listMonthFines';
import ListUserFines from './listUserFines';

const Fines = () => {
    return (
        <>
            {JSON.parse(sessionStorage.getItem('user'))?.role_id === 1 ? <ListUserFines /> : <ListMonthFines />}
        </>
    );
};

export default Fines;
