import { getAuth } from '../authService';
const API_URL = process.env.REACT_APP_API_URL;

const endPoint = {
    list: 'api/user/list',
    listGroup: 'api/user/list-group',
};

export const listUser = async () => {
    return await getAuth(API_URL + endPoint.list);
};

export const listUserGroup = async () => {
    return await getAuth(API_URL + endPoint.listGroup);
};
