import { Button, Card, Col, Row, Tag, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { listUserGroup } from '../../api/service/userService';
import { positions } from '../../assets/constants';
import SpinLoading from '../../components/loading';
import TabsCustom from '../../components/navTable';
import TableCustom from '../../components/table';
import Create from './create';

const { Title } = Typography;
const ListUserPayment = ({ openDrawer, setOpenDrawer }) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    const fetchList = async () => {
        setLoading(true);
        const getUserGroup = await listUserGroup();
        if (getUserGroup?.data?.meta?.status === 200) {
            setLoading(false);
            const listUserGroup = Object.entries(getUserGroup?.data?.response);
            setData(listUserGroup);
        }
    };

    useEffect(() => {
        fetchList();
    }, []);

    const columns = [
        {
            title: 'Employee',
            dataIndex: 'user',
            render: (user, row) => (
                <span>
                    {row?.first_name} {row?.surname}
                </span>
            ),
        },
        {
            title: 'Position',
            dataIndex: 'position',
            render: (position) => <Tag style={{ textAlign: 'center' }}>{positions.filter((pos) => pos?.value === position)[0]?.label}</Tag>,
        },

        {
            title: 'Action',
            dataIndex: 'id',
            align: 'center',
            render: (user_id, row) => {
                return (
                    <Button type="primary" onClick={() => navigate(`/payments/${user_id}`)} disabled={!row?.payment}>
                        View
                    </Button>
                );
            },
        },
    ];

    const onChange = (pagination) => {
        //
    };

    const onChangeTab = (key) => {
        //
    };

    const handleParentCall = () => {
        fetchList();
    };

    return (
        <>
            <Card>
                <Row gutter={[16, 16]}>
                    <Col span={12} align='left'>
                        <Create handleCallBack={handleParentCall} />
                    </Col>
                </Row>
                <Title level={4}>List User</Title>
                {loading ? (
                    <div style={{ marginTop: 15 }}>
                        <SpinLoading />
                    </div>
                ) : (
                    <TabsCustom
                        onChange={onChangeTab}
                        type="card"
                        items={data.map((e, i) => {
                            return {
                                key: i,
                                label: e[0] !== '' ? e[0] : 'Other',
                                children: (
                                    <TableCustom
                                        size="middle"
                                        loading={loading}
                                        columns={columns}
                                        dataSource={e[1].map((ex) => {
                                            return {
                                                key: ex?.id,
                                                ...ex,
                                            };
                                        })}
                                        onChange={onChange}
                                        pagination={{ defaultPageSize: 10 }}
                                    />
                                ),
                            };
                        })}
                    />
                )}
            </Card>
        </>
    );
};

export default ListUserPayment;
