import { deleteAuth, getAuth, postAuth, putAuth } from '../authService';
// const API_URL = 'http://localhost:8001/';
const API_URL = process.env.REACT_APP_API_URL;

const endPoint = {
    listUpcoming: 'api/payments/upcoming/',
    listHistory: 'api/payments/history/',
    list: 'api/payments',
    detail: 'api/payments/detail/',
    create: 'api/payments/store',
    update: 'api/payments/update/',
    delete: 'api/payments/delete/',
    totalAmount: 'api/payments/total-amount/',
    listMonth: 'api/payments/list-month/',
    paid: 'api/payments/paid/',
    clone: 'api/payments/clone/',
};

export const list = async () => {
    return await getAuth(API_URL + endPoint.list);
};

export const listUpcoming = async (userId) => {
    return await getAuth(API_URL + endPoint.listUpcoming + userId);
};

export const listHistory = async (userId, param) => {
    return await getAuth(API_URL + endPoint.listHistory + userId, param);
};

export const detail = async (data) => {
    return await getAuth(API_URL + endPoint.detail + data);
};

export const totalAmountUser = async (userId) => {
    return await getAuth(API_URL + endPoint.totalAmount + userId);
};

export const deletePayment = async (id) => {
    return await deleteAuth(API_URL + endPoint.delete + id);
};

export const create = async (data) => {
    return await postAuth(API_URL + endPoint.create, data);
};

export const update = async (id, data) => {
    return await putAuth(API_URL + endPoint.update + id, data);
};

export const paid = async (id) => {
    return await postAuth(API_URL + endPoint.paid + id);
};

export const clone = async (id, data) => {
    return await postAuth(API_URL + endPoint.clone + id, data);
};

export const listMonthPayment = async (userId, data) => {
    return await getAuth(API_URL + endPoint.listMonth + userId, data);
};
