/* eslint-disable jsx-a11y/anchor-is-valid */
import { MenuOutlined } from '@ant-design/icons';
import { Layout } from 'antd';
import { encode } from 'js-base64';
import Logo from '../../assets/logos/default-avatar.jpg';

const { Header } = Layout;
const LayoutHeader = ({ openDrawer, visible }) => {
    const redirectToOneLogin = () => {
        window.location.href = `${process.env.REACT_APP_DOMAIN_SSO}verify/${encode(sessionStorage.getItem('accessToken'))}`
    }
    return (
        <Header className="site-layout__header">
            <div className="site-layout__header__logo">
                <div>

                    <a onClick={redirectToOneLogin} href="#">
                        <img alt="Logo" src={Logo} width="75px" />
                    </a>
                </div>
            </div>
            <div className="site-layout__header__title">
                <span className="text-style-shadow h1">Payments & Claims</span>
            </div>
            <div style={{ flexGrow: 1 }}></div>
            <div className="site-layout__header__name">
                <div>
                    <span className="text-style-shadow h4">Welcome</span>
                    <br />
                    <span className="text-style-shadow h1">{JSON.parse(sessionStorage.getItem('user'))?.first_name}</span>
                </div>
            </div>
            <div className="site-layout__header__menu">
                <MenuOutlined onClick={() => openDrawer(!visible)} />
            </div>
        </Header>
    );
};

export default LayoutHeader;
