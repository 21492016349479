import Icon, { CopyOutlined, FolderOutlined } from '@ant-design/icons';
import { Button, Col, Form, Modal, Row, Space, Typography } from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DatePickerTKG, InputNumberTKG, InputTKG, PopupStatusTKG, SelectTKG } from 'tkg-composite-ui';
import { clone, listHistory } from '../../api/service/paymentsService';
import { currencyAll, format_money } from '../../assets/constants';
import TableCustom from '../../components/table';

const { Title } = Typography;

const FilterIconSvg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="Capa_1" viewBox="0 0 54 54" style={{ width: 25 }}>
        <path style={{ fill: '#7383BF' }} d="M5,10H1c-0.553,0-1-0.447-1-1s0.447-1,1-1h4c0.553,0,1,0.447,1,1S5.553,10,5,10z" />
        <path style={{ fill: '#7383BF' }} d="M53,29H33c-0.553,0-1-0.447-1-1s0.447-1,1-1h20c0.553,0,1,0.447,1,1S53.553,29,53,29z" />
        <path style={{ fill: '#7383BF' }} d="M53,10H17c-0.553,0-1-0.447-1-1s0.447-1,1-1h36c0.553,0,1,0.447,1,1S53.553,10,53,10z" />
        <path style={{ fill: '#7383BF' }} d="M21,29H1c-0.553,0-1-0.447-1-1s0.447-1,1-1h20c0.553,0,1,0.447,1,1S21.553,29,21,29z" />
        <path style={{ fill: '#7383BF' }} d="M37,48H1c-0.553,0-1-0.447-1-1s0.447-1,1-1h36c0.553,0,1,0.447,1,1S37.553,48,37,48z" />
        <path style={{ fill: '#7383BF' }} d="M53,48h-4c-0.553,0-1-0.447-1-1s0.447-1,1-1h4c0.553,0,1,0.447,1,1S53.553,48,53,48z" />
        <rect x="5" y="4" style={{ fill: '#424A60' }} width="5" height="10" />
        <rect x="21" y="23" style={{ fill: '#424A60' }} width="5" height="10" />
        <rect x="37" y="42" style={{ fill: '#424A60' }} width="5" height="10" />
        <rect x="5" y="2" style={{ fill: '#424A60' }} width="5" height="10" />
        <rect x="21" y="21" style={{ fill: '#424A60' }} width="5" height="10" />
        <rect x="37" y="40" style={{ fill: '#424A60' }} width="5" height="10" />
    </svg>
);

const HistoryPayments = ({ id, handleCallBack, user, userSelect }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalCloneOpen, setIsModalCloneOpen] = useState(false);
    const [form] = Form.useForm();
    const [formClone] = Form.useForm();

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [workPe, setWorkPe] = useState([]);
    const [paginate, setPaginate] = useState({});
    const navigate = useNavigate();

    const [page, setPage] = useState(1);

    const fetchList = async (page) => {
        setLoading(true);
        await listHistory(id, { page }).then((res) => {
            if (res?.data?.meta?.status === 200) {
                setPaginate({ total: res?.data?.response?.total, current: res?.data?.response?.current_page, pageSize: 5 });
                setData(
                    res?.data?.response?.data?.map((e) => {
                        return {
                            key: e?.id,
                            action: e,
                            ...e,
                        };
                    }),
                );
            }
            setLoading(false);
        });
    };
    useEffect(() => {
        fetchList(page);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, page]);

    const showModalClone = () => {
        setIsModalCloneOpen(true);
    };

    const columns = [
        {
            title: 'Title',
            dataIndex: 'title',
        },
        {
            title: 'Work Period',
            dataIndex: 'work_period',
            render: (work_period) => work_period && moment(JSON.parse(work_period)[0]).format('DD-MMM-YYYY') + ' To: ' + moment(JSON.parse(work_period)[1]).format('DD-MMM-YYYY'),
        },
        // {
        //     title: 'Currency',
        //     dataIndex: 'currency',
        //     align: 'center',
        // },
        {
            title: `Amount (${userSelect?.currency ? userSelect?.currency : JSON.parse(sessionStorage.getItem('user'))?.currency})`,
            dataIndex: 'amount',
            align: 'center',
            render: (amount) => <b>{format_money(amount)}</b>,
        },
        {
            title: 'Payment Date',
            dataIndex: 'payment_date',
            align: 'center',
            render: (payment_date) => moment(payment_date).format('DD-MMM-YYYY'),
        },
        {
            title: 'Action',
            dataIndex: 'action',
            align: 'center',
            render: (action) => (
                <Space>
                    {user?.id === 3 && action?.paid === 1 ?
                        <>
                            <Button type="primary" ghost onClick={showModalClone} icon={<CopyOutlined />}>
                                Clone
                            </Button>
                            <Modal
                                title="Clone Payment"
                                open={isModalCloneOpen}
                                onOk={formClone.submit}
                                onCancel={handleCancel}
                                okText="Clone"
                            >
                                <Form
                                    id="clone"
                                    name="clone"
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                    form={formClone}
                                    onFinish={onFinishClone}
                                    onFinishFailed={onFinishCloneFailed}
                                    initialValues={{ id: action?.id }}
                                >
                                    <Form.Item
                                        form={formClone}
                                        name="id"
                                        style={{ height: 0, margin: 0 }}
                                    >
                                        <InputTKG type="hidden" />
                                    </Form.Item>
                                    <Form.Item
                                        form={formClone}
                                        label="Title"
                                        name="title"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please select Title',
                                            },
                                        ]}
                                    >
                                        <InputTKG />
                                    </Form.Item>
                                    <Form.Item
                                        form={formClone}
                                        label="Month"
                                        name="month"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please select Month',
                                            },
                                        ]}
                                    >
                                        <DatePickerTKG picker="month" format="MMM-YYYY" onChange={e => handleChangeMonth(e)} />
                                    </Form.Item>
                                    <Form.Item
                                        form={formClone}
                                        label="Payment Date"
                                        name="payment_date"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please select Payment Date',
                                            },
                                        ]}
                                    >
                                        <DatePickerTKG />
                                    </Form.Item>
                                </Form>
                            </Modal>
                        </>
                        :
                        <></>
                    }
                    <Button type="primary" className="btnTKGAction" icon={<FolderOutlined />} ghost onClick={() => navigate(`/payments/detail/${action?.id}`)}>
                        View
                    </Button>
                </Space>
            ),
        },
    ];
    const onChange = (page) => {
        setPaginate(page);
        setPage(page.current);
    };

    const showModal = () => {
        setIsModalOpen(true);
    };

    const onFinish = async (values) => {
        setLoading(true);
        const data = {
            ...values,
            payment_date: values.payment_date ? values.payment_date.format('YYYY-MM-DD') : null,
            type: 1,
        };
        const listPayments = await listHistory(id, data);
        if (listPayments?.data?.meta?.status === 200) {
            setLoading(false);
            setData(
                listPayments?.data?.response?.data?.map((e) => {
                    return {
                        key: e?.id,
                        action: e,
                        ...e,
                    };
                }),
            );
            setIsModalOpen(false);
        }
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const handleCancel = () => {
        form.resetFields();
        setIsModalOpen(false);
        setIsModalCloneOpen(false);
    };

    const handleChangeMonth = (e) => {
        const startOfMonth = dayjs(e.format('YYYY-MM')).startOf('month');
        const endOfMonth = dayjs(e.format('YYYY-MM')).endOf('month');
        return setWorkPe([startOfMonth, endOfMonth]);
    }

    const onFinishClone = async (values) => {
        setLoading(true);
        const data = {
            ...values,
            work_period: workPe,
            payment_date: values?.payment_date ? values?.payment_date.format('YYYY-MM-DD') : null,
            month: values?.month ? values?.month.format('YYYY-MM') : null,
        };
        const clonePayments = await clone(values?.id, data);
        if (clonePayments?.data?.meta?.status === 200) {
            setLoading(false);
            setIsModalCloneOpen(false);
            handleCallBack(clonePayments?.data?.meta?.status === 200);
            PopupStatusTKG('success', 'Clone Payment Successfully');
            formClone.resetFields();
        }
    };
    const onFinishCloneFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <>
            <Title level={3}>Payment History </Title>
            <Button type="link" onClick={showModal}>
                <Space>
                    <span style={{ textDecorationLine: 'underline' }}>Show filters</span>
                    <Icon component={FilterIconSvg} />
                </Space>
            </Button>
            <br />
            <br />

            <TableCustom
                loading={loading}
                columns={columns}
                dataSource={data}
                pagination={paginate}
                onChange={onChange}
                scroll={{
                    x: 700,
                }}
            />

            <Modal
                title="Filters"
                open={isModalOpen}
                okText="Search"
                onOk={form.submit}
                onCancel={handleCancel}
                width={window.innerWidth > 900 ? '60%' : '100%'}
            >
                <Form
                    layout="vertical"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    form={form}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    autoComplete="off"
                >
                    <Row gutter={[16, 16]}>
                        <Col span={12}>
                            <Form.Item name="currency" label="Currency">
                                <SelectTKG form={form} options={currencyAll} allowClear />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="amount" label="Amount">
                                <InputNumberTKG form={form} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="payment_date" label="Payment Date">
                                <DatePickerTKG form={form} format="DD/MM/YYYY" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    );
};
export default HistoryPayments;
