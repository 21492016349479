import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Row } from 'antd';
import { useState } from 'react';
import styled from 'styled-components';
import { DatePickerTKG, InputNumberTKG, InputTKG, ModalFormTKG, PopupStatusTKG, SelectTKG, TextEditorTKG } from 'tkg-composite-ui';
import { create } from '../../api/service/claimService';

const CardContent = styled.div`
    background: #fff;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 3%), 0 1px 6px -1px rgb(0 0 0 / 2%), 0 2px 4px 0 rgb(0 0 0 / 2%);
    border-radius: 10px;
    margin-top: 0.2em;
    padding: 1em;
`;

const Create = ({ dataUser, handleCallback, page }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [checkNull, setCheckNull] = useState(false);

    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        form.resetFields();
        setOpen(false);
    };

    const getLengthTextEditor = (length, html, text) => {
        if (length > 5000) {
            setCheckNull(true);
        } else {
            setCheckNull(false);
        }
    };

    const onFinish = async (values) => {
        if (checkNull) {
            console.log('Null');
        } else {
            setLoading(true);
            const dateClaims = values['date_claims'];
            const data = {
                ...values,
                status: 'pending',
                date_claims: dateClaims ? dateClaims?.format('YYYY-MM-DD') : null,
            };
            const claimsResponse = await create(data);
            if (claimsResponse?.data?.meta?.status === 200) {
                form.resetFields();
                setLoading(false);
                setOpen(false);
                handleCallback(page);
                PopupStatusTKG('success', 'Create successfully');
            } else {
                setLoading(false);
            }
        }
    };

    const handleGetCurrency = async (e) => {
        form.setFieldsValue({ currency: dataUser.find((us) => us?.value === e)?.currency });
    };

    const onFinishFailed = (errorInfo) => {
        getLengthTextEditor();
        console.log('Failed:', errorInfo);
    };

    return (
        <>
            <Button type="primary" onClick={showDrawer} icon={<PlusOutlined />}>
                Create Claims
            </Button>
            <ModalFormTKG title="Create Claims" closable={false} onClose={onClose} open={open}>
                <CardContent>
                    <Form
                        layout="vertical"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        form={form}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        autoComplete="off"
                    >
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="user_id"
                                    label="Employee"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select the employee',
                                        },
                                    ]}
                                >
                                    <SelectTKG
                                        showSearch
                                        options={dataUser}
                                        onChange={handleGetCurrency}
                                        filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="title"
                                    label="Title"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter the title',
                                        },
                                    ]}
                                >
                                    <InputTKG form={form} placeholder="Please enter the title" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="amount"
                                    label="Amount"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter the amount',
                                        },
                                    ]}
                                >
                                    <InputNumberTKG
                                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        form={form}
                                        placeholder="Please enter the amount"
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="currency"
                                    label="Currency"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select currency!',
                                        },
                                    ]}
                                >
                                    <InputTKG readOnly form={form} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="date_claims"
                                    label="Date Claims"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter the date claims',
                                        },
                                    ]}
                                >
                                    <DatePickerTKG form={form} placeholder="Please enter the date claims" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item name="detail" label="Detail">
                                    <TextEditorTKG form={form} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Button type="primary" htmlType="submit" loading={loading}>
                                    Submit
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </CardContent>
            </ModalFormTKG>
        </>
    );
};

export default Create;
