import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import App from './App';
import './index.css';
import Claims from './pages/claims';
import DetailUserClaims from './pages/claims/detailUserClaims';
import ListMonthClaims from './pages/claims/listMonthClaims';
import Fines from './pages/fines';
import DetailUserFines from './pages/fines/detailUserFines';
import ListMonthFines from './pages/fines/listMonthFines';
import Payment from './pages/payments';
import DetailPayment from './pages/payments/detail';
import DetailUserPayment from './pages/payments/detailUserPayment';
import Verify from './pages/verifyAccount';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    // <React.StrictMode>
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<App />}>
                <Route index element={<Navigate to="/payments" />} />
                <Route path="verify/:token" element={<Verify />} />
                <Route path="payments" element={<Payment />} />
                <Route path="payments/:id" element={<DetailUserPayment />} />
                <Route path="payments/detail/:id" element={<DetailPayment />} />
                <Route path="claims" element={<Claims />} />
                <Route path="claims/month/:id" element={<ListMonthClaims />} />
                <Route path="claims/detail/:id/:date" element={<DetailUserClaims />} />
                <Route path="fines" element={<Fines />} />
                <Route path="fines/month/:id" element={<ListMonthFines />} />
                <Route path="fines/detail/:id/:date" element={<DetailUserFines />} />
            </Route>
        </Routes>
    </BrowserRouter>
    // </React.StrictMode>,
);

reportWebVitals();
