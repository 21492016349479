/* eslint-disable react-hooks/exhaustive-deps */
import { ArrowLeftOutlined, DollarOutlined } from '@ant-design/icons';
import { Button, Col, Row, Space, Table, Typography } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { ModalFormTKG } from 'tkg-composite-ui';
import { detail, paid } from '../../api/service/paymentsService';
import { format_money, positions, typeOverTime, typePayments } from '../../assets/constants';
import { TableBoderTwoLines } from '../../components/table';

const { Text } = Typography;

const DetailPayment = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const { id } = useParams();
    const [data, setData] = useState([]);
    const userId = JSON.parse(sessionStorage.getItem('user'))?.id;
    const userRole = JSON.parse(sessionStorage.getItem('user'))?.role_id;
    const [netWages, setNetWages] = useState(0);
    const [open, setOpen] = useState(false);
    const [numberWorkInMonth, setNumberWorkInMonth] = useState(0);

    const fetchDetail = async () => {
        setLoading(true);
        const detailPayments = await detail(id);
        if (detailPayments?.data?.meta?.status === 200) {
            setLoading(false);
            setData(
                [detailPayments?.data?.response].map((e) => {
                    return {
                        key: e?.id,
                        ...e,
                    };
                }),
            );
            const totalDays = moment(JSON.parse(detailPayments?.data?.response?.work_period)[1]).diff(moment(JSON.parse(detailPayments?.data?.response?.work_period)[0]), 'days') + 1;
            const dayOfWeek = moment(JSON.parse(detailPayments?.data?.response?.work_period)[0]).isoWeekday();
            let totalWorkdays = 0;

            for (let i = dayOfWeek; i < totalDays + dayOfWeek; i++) {
                if (i % 7 !== 6 && i % 7 !== 0) {
                    totalWorkdays++;
                }
            }
            setNumberWorkInMonth(totalWorkdays);
            let net = 0;
            [detailPayments?.data?.response].map((e) => {
                net = net + Number(e?.amount);
                if (e?.kpi_bonus) {
                    JSON.parse(e?.kpi_bonus).map((k) => {
                        return net = net + Number(k?.amount);
                    })
                }
                if (e?.claim) {
                    JSON.parse(e?.claim).map((c) => {
                        return net = net + Number(c?.amount);
                    })
                }
                if (e?.overtime) {
                    JSON.parse(e?.overtime).map((ot) => {
                        return net = net + Number(ot?.hours_amount);
                    })
                }
                if (e?.other) {
                    JSON.parse(e?.other).map((o) => {
                        return net = net + Number(o?.amount);
                    })
                }
                if (e?.fine) {
                    JSON.parse(e?.fine).map((f) => {
                        return net = net - Number(f?.amount);
                    })
                }
                if (e?.less) {
                    JSON.parse(e?.less).map((t) => {
                        return net = net - Number(t?.amount);
                    })
                }
                if (e?.other_less) {
                    JSON.parse(e?.other_less).map((o) => {
                        return net = net - Number(o?.amount);
                    })
                }
                return net;
            })
            setNetWages(net);

            if (userRole !== 1 && userId !== detailPayments?.data?.response?.user_id) {
                navigate(`/payments`);
            }
        }
    };

    useEffect(() => {
        fetchDetail();
    }, []);

    const showNote = () => {
        setOpen(true);
    }

    const onClose = () => {
        setOpen(false);
    }

    const columnsKPIBonus = [
        {
            title: '',
            dataIndex: 'title',
            width: '75%',
        },
        // {
        //     title: 'Date',
        //     dataIndex: 'date',
        //     align: 'center',
        //     width: '15%',
        //     render: (date) => date ? moment(date).format('DD-MMM-YYYY') : '',
        // },
        // {
        //     title: 'Currency',
        //     dataIndex: 'currency',
        //     align: 'center',
        //     width: '10%',
        // },
        {
            title: `Amount (${data[0]?.user?.currency})`,
            dataIndex: 'amount',
            align: 'center',
            width: '25%',
            render: (amount) => '+ ' + format_money(amount)
        },
    ];

    const columnsOvertime = [
        {
            title: '',
            dataIndex: 'title',
            width: '38%',
        },
        {
            title: 'Date',
            dataIndex: 'date',
            align: 'center',
            width: '12%',
            render: (date) => date ? moment(date).format('DD-MMM-YYYY') : '',
        },
        {
            title: 'Type',
            dataIndex: 'type',
            align: 'center',
            width: '15%',
            render: (type) => typeOverTime.filter((t) => t.value === type)[0]?.label,
        },
        {
            title: 'Hours',
            dataIndex: 'hours',
            align: 'center',
            width: '10%',
            render: (hours) => hours ? format_money(hours) + 'h' : '',
        },
        {
            title: '', //`Amount (${user?.currency})`,
            dataIndex: 'hours_amount',
            align: 'center',
            width: '25%',
            render: (hours_amount) => '+ ' + format_money(hours_amount),
        },
    ];

    const columnsClaim = [
        {
            title: '',
            dataIndex: 'title',
            width: '75%',
        },
        // {
        //     title: 'Date',
        //     dataIndex: 'date',
        //     align: 'center',
        //     width: '15%',
        //     render: (date) => date ? moment(date).format('DD-MMM-YYYY') : '',
        // },
        {
            title: `Amount (${data[0]?.user?.currency})`,
            dataIndex: 'amount',
            align: 'center',
            width: '25%',
            render: (amount) => '+ ' + format_money(amount),
        },
    ];

    const columnsOther = [
        {
            title: '',
            dataIndex: 'title',
            width: '55%',
        },
        {
            title: 'Date',
            dataIndex: 'date',
            align: 'center',
            width: '10%',
            render: (date) => date ? moment(date).format('DD-MMM-YYYY') : '',
        },
        {
            title: 'Hours',
            dataIndex: 'hours',
            align: 'center',
            width: '10%',
            render: (hours) => hours ? format_money(hours) + 'h' : '',
        },
        {
            title: `Amount (${data[0]?.user?.currency})`,
            dataIndex: 'amount',
            align: 'center',
            width: '25%',
            render: (amount) => '+ ' + format_money(amount),
        },
    ];

    const columnsFine = [
        {
            title: '',
            dataIndex: 'title',
            width: '75%',
        },
        // {
        //     title: 'Date',
        //     dataIndex: 'date',
        //     align: 'center',
        //     width: '15%',
        //     render: (date) => date ? moment(date).format('DD-MMM-YYYY') : '',
        // },
        {
            title: `Amount (${data[0]?.user?.currency})`,
            dataIndex: 'amount',
            align: 'center',
            width: '25%',
            render: (amount) => '- ' + format_money(amount),
        },
    ];

    const columnsTax = [
        {
            title: '',
            dataIndex: 'title',
            width: '75%',
        },
        // {
        //     title: 'Date',
        //     dataIndex: 'date',
        //     align: 'center',
        //     width: '15%',
        //     render: (date) => date ? moment(date).format('MMM-YYYY') : '',
        // },
        {
            title: `Amount (${data[0]?.user?.currency})`,
            dataIndex: 'amount',
            align: 'center',
            width: '25%',
            render: (amount) => '- ' + format_money(amount),
        },
    ];

    const columnsOtherLess = [
        {
            title: '',
            dataIndex: 'title',
            width: '55%',
        },
        {
            title: 'Date',
            dataIndex: 'date',
            align: 'center',
            width: '10%',
            render: (date) => date ? moment(date).format('DD-MMM-YYYY') : '',
        },
        {
            title: 'Hours',
            dataIndex: 'hours',
            align: 'center',
            width: '10%',
            render: (hours) => hours ? format_money(hours) + 'h' : '',
        },
        {
            title: `Amount (${data[0]?.user?.currency})`,
            dataIndex: 'amount',
            align: 'center',
            width: '25%',
            render: (amount) => '- ' + format_money(amount),
        },
    ];

    const columns = [
        {
            title: 'Title',
            dataIndex: 'title',
        },
        {
            title: 'Type',
            dataIndex: 'type',
            render: (type) => <b>{typePayments.filter((t) => t.value === Number(type))[0]?.label}</b>,
        },
        {
            title: 'Work Period',
            dataIndex: 'work_period',
            render: (work_period) =>
                moment(JSON.parse(work_period)[0]).format('DD-MMM-YYYY') + ' To: ' + moment(JSON.parse(work_period)[1]).format('DD-MMM-YYYY'),
        },
        // {
        //     title: 'Currency',
        //     dataIndex: 'currency',
        //     align: 'center',
        //     render: (_) => user?.currency,
        // },
        {
            title: 'Work Days',
            dataIndex: 'work_days',
            align: 'center',
        },
        {
            title: `Amount (${data[0]?.user?.currency})`,
            dataIndex: 'amount',
            align: 'center',
            width: '25%',
            render: (amount) => <b> {format_money(amount)}</b>,
        },
    ];

    const showPayment = () => {
        Swal.fire({
            icon: 'warning',
            title: '',
            text: 'Are you sure about the payment for this employee?',
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Yes, Payment!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                setLoading(true);
                const paidRes = await paid(data[0]?.id);
                if (paidRes?.data?.meta?.status === 200) {
                    fetchDetail();
                    Swal.fire({
                        icon: 'success',
                        title: '',
                        text: paidRes?.data?.response,
                        showConfirmButton: true,
                    })
                    setLoading(false);
                }
            }
        })
    }

    const handleBack = () => {
        return navigate(-1);
    };
    return (
        <>
            <Row gutter={[16, 16]} align="middle" justify="center">
                <Col span={10} align='left'>
                    <Button icon={<ArrowLeftOutlined />} onClick={handleBack}>Back</Button>
                </Col>
                <Col span={10} align='right'>
                    {data[0]?.paid === 0 && data[0]?.user?.role_id === 1 ? <Button type='primary' icon={<DollarOutlined />} onClick={showPayment}>Payment</Button> : <></>}
                </Col>
                <Col span={20}>
                    <Typography className="payment-fs20 payment-fw700 payment-pt2">Payment Details</Typography>
                </Col>
                <Col span={20}>
                    <Row gutter={[16, 16]}>
                        <Col xs={16} md={7} lg={5} className="payment-fs16">
                            Annual Salary ({data[0]?.user?.currency}):
                        </Col>
                        <Col xs={8} md={4} lg={4} className="payment-fs16">
                            {data[0]?.user?.yearly_salary ? format_money(data[0]?.user?.yearly_salary) : 0}
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col xs={16} md={7} lg={5} className="payment-fs16">
                            Hourly Rate ({data[0]?.user?.currency}):
                        </Col>
                        <Col xs={8} md={4} lg={4} className="payment-fs16">
                            {data[0]?.user?.yearly_salary ? format_money(Math.round(data[0]?.user?.yearly_salary / numberWorkInMonth / 8), 0) : 0}
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col xs={16} md={7} lg={5} className="payment-fs16">
                            Position:
                        </Col>
                        <Col xs={8} md={12} lg={12} className="payment-fs16">
                            {positions.filter((pos) => pos.value === data[0]?.user?.position)[0]?.label}
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col xs={16} md={7} lg={5} className="payment-fs16">
                            Note:
                        </Col>
                        <Col xs={8} md={12} lg={12} className="payment-fs16">
                            <Text onClick={showNote} underline={true} style={{ cursor: 'pointer', color: 'blue' }}>View</Text>
                        </Col>
                    </Row>
                </Col>
                <Col span={20}>
                    <Typography className="payment-fs20 payment-fw700">Upcoming Payment Breakdown</Typography>
                </Col>
                <Col span={20}>
                    <TableBoderTwoLines
                        className="payment-fs12"
                        loading={loading}
                        bordered={true}
                        columns={columns}
                        dataSource={data}
                        pagination={false}
                        scroll={{
                            x: 700,
                        }}
                    />
                </Col>
            </Row>
            <Row gutter={[16, 16]} align="middle" justify="center">
                <Col span={20}>
                    <Typography className="payment-fs20 payment-fw700 payment-pt1">More Payments</Typography>
                </Col>

                {data[0]?.kpi_bonus ?
                    <Col span={20}>
                        <Row gutter={[16, 8]}>
                            <Col span={24} className="payment-fs16 payment-fw600">
                                KPI Bonus
                            </Col>
                            <Col span={24}>
                                <Table
                                    size='small'
                                    className="payment-fs12 hide-header"
                                    bordered={true}
                                    columns={columnsKPIBonus}
                                    dataSource={JSON.parse(data[0]?.kpi_bonus).map((e, i) => { return { key: i, ...e } })}
                                    pagination={false}
                                    scroll={{
                                        x: 700,
                                    }}
                                />
                            </Col>
                        </Row>
                    </Col>
                    :
                    <></>
                }
                {data[0]?.overtime ?
                    <Col span={20}>
                        <Row gutter={[16, 8]}>
                            <Col span={24} className="payment-fs16 payment-fw600">
                                Overtime
                            </Col>
                            <Col span={24}>
                                <Table
                                    size='small'
                                    className="payment-fs12 hide-header"
                                    bordered={true}
                                    columns={columnsOvertime}
                                    dataSource={JSON.parse(data[0]?.overtime).map((e, i) => { return { key: i, ...e } })}
                                    pagination={false}
                                    scroll={{
                                        x: 700,
                                    }}
                                />
                            </Col>
                        </Row>
                    </Col>
                    :
                    <></>
                }
                {data[0]?.claim ?
                    <Col span={20}>
                        <Row gutter={[16, 8]}>
                            <Col span={24} className="payment-fs16 payment-fw600">
                                Claim
                            </Col>
                            <Col span={24}>
                                <Table
                                    size='small'
                                    className="payment-fs12 hide-header"
                                    bordered={true}
                                    columns={columnsClaim}
                                    dataSource={JSON.parse(data[0]?.claim).map((e, i) => { return { key: i, ...e } })}
                                    pagination={false}
                                    scroll={{
                                        x: 700,
                                    }}
                                />
                            </Col>
                        </Row>
                    </Col>
                    :
                    <></>
                }
                {data[0]?.other ?
                    <Col span={20}>
                        <Row gutter={[16, 8]}>
                            <Col span={24} className="payment-fs16 payment-fw600">
                                Other
                            </Col>
                            <Col span={24}>
                                <Table
                                    size='small'
                                    className="payment-fs12 hide-header"
                                    bordered={true}
                                    columns={columnsOther}
                                    dataSource={JSON.parse(data[0]?.other).map((e, i) => { return { key: i, ...e } })}
                                    pagination={false}
                                    scroll={{
                                        x: 700,
                                    }}
                                />
                            </Col>
                        </Row>
                    </Col>
                    :
                    <></>
                }
                <Col span={20}>
                    <Typography className="payment-fs20 payment-fw700 payment-pt1">LESS</Typography>
                </Col>

                {data[0]?.fine ?
                    <Col span={20}>
                        <Row gutter={[16, 8]}>
                            <Col span={24} className="payment-fs16 payment-fw600">
                                Fines
                            </Col>
                            <Col span={24}>
                                <Table
                                    size='small'
                                    className="payment-fs12 hide-header"
                                    bordered={true}
                                    columns={columnsFine}
                                    dataSource={JSON.parse(data[0]?.fine).map((e, i) => { return { key: i, ...e } })}
                                    pagination={false}
                                    scroll={{
                                        x: 700,
                                    }}
                                />
                            </Col>
                        </Row>
                    </Col>
                    :
                    <></>
                }

                {data[0]?.less ?
                    <Col span={20}>
                        <Row gutter={[16, 8]}>
                            <Col span={24} className="payment-fs16 payment-fw600">
                                Tax
                            </Col>
                            <Col span={24}>
                                <Table
                                    size='small'
                                    className="payment-fs12 hide-header"
                                    bordered={true}
                                    columns={columnsTax}
                                    dataSource={JSON.parse(data[0]?.less).map((e, i) => { return { key: i, ...e } })}
                                    pagination={false}
                                    scroll={{
                                        x: 700,
                                    }}
                                />
                            </Col>
                        </Row>
                    </Col>
                    :
                    <></>
                }

                {data[0]?.other_less ?
                    <Col span={20}>
                        <Row gutter={[16, 8]}>
                            <Col span={24} className="payment-fs16 payment-fw600">
                                Other
                            </Col>
                            <Col span={24}>
                                <Table
                                    size='small'
                                    className="payment-fs12 hide-header"
                                    bordered={true}
                                    columns={columnsOtherLess}
                                    dataSource={JSON.parse(data[0]?.other_less).map((e, i) => { return { key: i, ...e } })}
                                    pagination={false}
                                    scroll={{
                                        x: 700,
                                    }}
                                />
                            </Col>
                        </Row>
                    </Col>
                    :
                    <></>
                }
                <Col span={20} style={{ paddingBottom: 40 }}>
                    <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                        <Row gutter={[16, 24]} className="payment-pt2">
                            <Col xs={12} md={18} lg={18} className="payment-fs18 payment-fw700">
                                Net Wages
                            </Col>
                            <Col xs={12} md={6} lg={6} className="payment-fs18 payment-center payment-fw700">
                                {data[0]?.less ? format_money(netWages) : data[0]?.user?.currency === 'VND' ? <div className="processing">Processing...</div> : format_money(netWages)}
                            </Col>
                        </Row>
                    </Space>
                </Col>
            </Row>
            <ModalFormTKG
                title="Payment's Note"
                footer={''}
                onClose={onClose}
                open={open}
            >
                <div dangerouslySetInnerHTML={{ __html: data[0]?.note }}></div>
            </ModalFormTKG>
        </>
    );
};

export default DetailPayment;
