import { DiffOutlined, DollarOutlined, PicLeftOutlined } from '@ant-design/icons';
import { NavLink } from 'react-router-dom';

const menu = [
    {
        label: <NavLink to={'/payments'}>Payments</NavLink>,
        key: 'payments',
        icon: <DollarOutlined />,
    },
    {
        label: <NavLink to={'/claims'}>Claims</NavLink>,
        key: 'claims',
        icon: <DiffOutlined />,
    },
    {
        label: <NavLink to={'/fines'}>Fines</NavLink>,
        key: 'fines',
        icon: <PicLeftOutlined />,
    },
];

export default menu;
